import React from "react";
import { Page } from "../../components/Page";
import { Title } from "../../components/Title";
import { Content } from "../../components/Content";
import { Subtitle } from "../../components/Subtitle";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const IndexPage = () => (
  <Page language="sv" page="Förstasidan">
    <Row>
      <Col>
        <Title>Varningstjänsten för halkan</Title>
      </Col>
    </Row>

    <Row>
      <Col md={9}>
        <Subtitle>
          Varning för halka sänds via textmeddelande, tjänsten är gratis för dem
          som varnas
        </Subtitle>

        <Content>
          Varningstjänsten har fått utmärkt feedback. En liten insats som ger
          mångdubbel nytta.
        </Content>

        <Content>
          Varningsmeddelandet sänds ut antingen av jourhavande för
          vinterunderhållet eller av YIT:s servicecentral PANU, som genom att
          följa vädersituationen dygnet runt i realtid även kallar ut
          sandningsbilar vid behov. Ett varningsmeddelande skickas ut enligt
          väderprognoserna, om den som varnar utifrån sin erfarenhet anser att
          uppkomsten av farlig halka i praktiken är säker. Utgångspunkten är att
          varningen grundar sig på en verklig situation.
        </Content>
      </Col>

      <Col md={3}>
        <Subtitle>Anmäl dig till tjänsten</Subtitle>

        <Content>
          Genom att klicka på stadens namn får du anvisningar om hur du anmäler
          dig till tjänsten:
        </Content>

        <Content as="ul">
          <li>
            <a href="/sv/anmalning/helsingfors">Helsingfors</a>
          </li>
        </Content>
      </Col>
    </Row>
  </Page>
);

export default IndexPage;
